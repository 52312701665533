import PageComponent from '../component/page-component';


class VideoPlayerCover extends PageComponent {

	constructor({
		element,
		root,
		linkAttribute = 'videoLink',
		loadingClass = 'loading',
		loadedClass = 'loaded'
	} = {}) {
		super({element: element, root: root});
		this.defaults.linkAttribute = linkAttribute;
		this.loadingClass = loadingClass;
		this.loadedClass = loadedClass;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.link = this.element.querySelector(this.dataSelector(data.linkAttribute));
		this.player = this.getComponent('VideoPlayer');
		if (this.link && this.player) {
			this.listeners.click = this.events.on(this.element, this.dataSelector(data.linkAttribute), 'click', this.onClick.bind(this));
		}
	}


	onClick(event) {
		if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
			event.preventDefault();
			const classList = this.classList();
			classList.add(this.loadingClass);
			this.player.load().then(() => {
				this.hide();
			});
		}
	}

	hide() {
		const classList = this.classList();
		classList.add(this.loadedClass);
		classList.remove(this.loadingClass);
	}

}


export default VideoPlayerCover;
